export const SET_VIEW_SR = 'set_view_update_sr';
export const RESET_VIEW_TAB_SR = 'reset_view_tab_update_sr';

export const LOAD_SINGLE_RATER = 'load_single_rater';
export const SET_SINGLE_RATER = 'set_single_rater_update';
export const RESET_SINGLE_RATER = 'reset_single_rater_update';

export const LOAD_SETTING_SR = 'load_setting_sr';
export const SET_SETTING_SR = 'set_setting_update_sr';
export const RESET_SETTING_SR = 'reset_setting_update_sr';

export const SET_VIEW_CONTENT_UPDATE_SR = 'set_view_content_update_sr';
export const CLEAR_VIEW_CONTENT_UPDATE_SR = 'clear_view_content_update_sr';
export const RESET_CONTENT_VIEW_SR = 'reset_content_view_update_sr';

export const LOAD_SECTION_CONTENT_SR = 'load_section_content_sr';
export const SET_SECTION_SR = 'set_section_update_sr';
export const EDIT_SECTION_SR = 'edit_section_update_sr';
export const DELETE_SECTION_SR = 'delete_section_update_sr';
export const RESET_SECTION_SR = 'reset_section_update_sr';

export const SET_CONTENT_SR = 'set_content_update_sr';
export const SET_CONTENT_BULK_SR = 'set_content_bulk_update_sr';
export const EDIT_CONTENT_SR = 'edit_content_update_sr';
export const DELETE_CONTENT_SR = 'delete_content_update_sr';

export const SET_CONTENT_CHOICE_SR = 'set_content_choice_update_sr';
export const EDIT_CONTENT_CHOICE_SR = 'edit_content_choice_update_sr';
export const DELETE_CONTENT_CHOICE_SR = 'delete_content_choice_update_sr';

export const LOAD_SCALE_CHOICE_SR = 'load_scale_choice_sr';
export const SET_SCALE_CHOICE_SR = 'set_scale_choice_update_sr';
export const ADD_SCALE_CHOICE_SR = 'add_scale_choice_update_sr';
export const EDIT_SCALE_CHOICE_SR = 'edit_scale_choice_update_sr';
export const DELETE_SCALE_CHOICE_SR = 'delete_scale_choice_update_sr';
export const CLEAR_SCALE_CHOICE_SR = 'clear_scale_choice_update_sr';
export const RESET_SCALE_CHOICE_SR = 'reset_scale_choice_update_sr';

export const LOAD_SCORE_SR = 'load_score_sr';
export const SET_SCORE_SR = 'set_score_update_sr';
export const RESET_SCORE_SR = 'reset_score_update_sr';

export const LOAD_PARTICIPANT_SR = 'load_participant_sr';
export const SET_PARTICIPANT_SR = 'set_participant_update_sr';
export const DELETE_PARTICIPANT_SR = 'delete_participant_update_sr';
export const RESET_PARTICIPANT_SR = 'reset_participant_update_sr';

export const SET_VIEW_EDIT_PARTICIPANT_SR = 'set_view_edit_participant_update_sr';
export const RESET_VIEW_EDIT_PARTICIPANT_SR = 'reset_view_edit_participant_update_sr';
export const EDIT_PARTICIPANT_SR = 'edit_participant_update_sr';

export const SET_IS_EDITING_SR = 'set_is_editing_update_sr';
export const RESET_IS_EDITING_SR = 'reset_is_editing_update_sr';

export const SET_CONTENT_CHOICE_SCALE_SR = 'set_content_choice_scale_update_sr';

/* VALIDATION */
export const SET_VALID_SETTING = 'set_valid_setting_update_sr';
export const SET_VALID_CONTENT = 'set_valid_content_update_sr';
export const SET_VALID_PARTICIPANT = 'set_valid_participant_update_sr';
export const RESET_VALID_INPUT = 'reset_valid_input_update_sr';

export const OWNERSHIP_LIST_SR = 'ownership_list_sr';
export const RESET_OWNERSHIP_SR = 'reset-ownership_sr';
export const DELETE_OWNERSHIP_SR = 'delete_ownership_sr';



export const SET_VIEW_EDIT_PARTICIPANT_KEY_EMAIL_SR = 'set_view_edit_participant_key_email_update_sr';
export const RESET_VIEW_EDIT_PARTICIPANT_KEY_EMAIL_SR = 'reset_view_edit_participant_key_email_update_sr';