import { ADD_SCALE_CHOICE_SINGLE, CLEAR_DATA_PREVIEW_SR, CLEAR_SCALE_CHOICE_SINGLE, CLEAR_VIEW_CONTENT_SINGLE, DELETE_CONTENT_CHOICE_SINGLE, DELETE_CONTENT_SINGLE, DELETE_PARTICIPANT_SINGLE, DELETE_SCALE_CHOICE_SINGLE, DELETE_SECTION_SINGLE, DUPLICATE_PARTICIPANT_SR, DUPLICATE_SCALE_CHOICE_SR, DUPLICATE_SCORE_SR, DUPLICATE_SECTION_CONTENT_SR, DUPLICATE_SETTING_SR, DUPLICATE_SINGLE_RATER, EDIT_CONTENT_CHOICE_SINGLE, EDIT_CONTENT_SINGLE, EDIT_PARTICIPANT_SINGLE, EDIT_SCALE_CHOICE_SINGLE, EDIT_SECTION_SINGLE, RESET_CONTENT_VIEW_SINGLE, RESET_PARTICIPANT_SINGLE, RESET_SCALE_CHOICE_SINGLE, RESET_SCORE_SINGLE, RESET_SECTION_SINGLE, RESET_SETTING_SINGLE, RESET_SINGLE_RATER, RESET_VALID_INPUT, RESET_VIEW_EDIT_PARTICIPANT_SINGLE, RESET_VIEW_EDIT_PARTICIPANT_SR_KEY_EMAIL, RESET_VIEW_TAB_SINGLE, SET_CONTENT_BULK_SR, SET_CONTENT_CHOICE_SCALE_SINGLE, SET_CONTENT_CHOICE_SINGLE, SET_CONTENT_SINGLE, SET_DATA_PREVIEW_SR, SET_PARTICIPANT_SINGLE, SET_SCALE_CHOICE_SINGLE, SET_SCORE_SINGLE, SET_SECTION_SINGLE, SET_SETTING_SINGLE, SET_SINGLE_RATER, SET_VALID_CONTENT, SET_VALID_PARTICIPANT, SET_VALID_SETTING, SET_VIEW_CONTENT_SINGLE, SET_VIEW_EDIT_PARTICIPANT_SINGLE, SET_VIEW_EDIT_PARTICIPANT_SR_KEY_EMAIL, SET_VIEW_SINGLE } from "./SingleRaterType";

// define action constant
const setSRViewAction = (reqData) => ({
   type: SET_VIEW_SINGLE,
   data: reqData
});

const setSRAction = (reqData) => ({
   type: SET_SINGLE_RATER,
   data: reqData
});

const setSettingSRAction = (reqData) => ({
   type: SET_SETTING_SINGLE,
   data: reqData
});

const setSectionSRAction = (reqData) => ({
   type: SET_SECTION_SINGLE,
   data: reqData
});

const editSectionSRAction = (reqData) => ({
   type: EDIT_SECTION_SINGLE,
   data: reqData
});

const deleteSectionSRAction = (reqData) => ({
   type: DELETE_SECTION_SINGLE,
   data: reqData
});

const setContentSRAction = (reqData) => ({
   type: SET_CONTENT_SINGLE,
   data: reqData
});

const setContentBulkSRAction = (reqData) => ({
   type: SET_CONTENT_BULK_SR,
   data: reqData
});

const editContentSRAction = (reqData) => ({
   type: EDIT_CONTENT_SINGLE,
   data: reqData
});

const deleteContentSRAction = (reqData) => ({
   type: DELETE_CONTENT_SINGLE,
   data: reqData
});

const setContentChoiceSRAction = (reqData) => ({
   type: SET_CONTENT_CHOICE_SINGLE,
   data: reqData
});

const setContentChoiceScaleSRAction = (reqData) => ({
   type: SET_CONTENT_CHOICE_SCALE_SINGLE,
   data: reqData
});

const editContentChoiceSRAction = (reqData) => ({
   type: EDIT_CONTENT_CHOICE_SINGLE,
   data: reqData
});

const deleteContentChoiceSRAction = (reqData) => ({
   type: DELETE_CONTENT_CHOICE_SINGLE,
   data: reqData
});

const setContentViewSRAction = (reqData) => ({
   type: SET_VIEW_CONTENT_SINGLE,
   data: reqData
});

const clearContentViewSRAction = () => ({
   type: CLEAR_VIEW_CONTENT_SINGLE
});

const setScaleChoiceSRAction = (reqData) => ({
   type: SET_SCALE_CHOICE_SINGLE,
   data: reqData
});

const addScaleChoiceSRAction = (reqData) => ({
   type: ADD_SCALE_CHOICE_SINGLE,
   data: reqData
});

const editScaleChoiceSRAction = (reqData) => ({
   type: EDIT_SCALE_CHOICE_SINGLE,
   data: reqData
});

const deleteScaleChoiceSRAction = (reqData) => ({
   type: DELETE_SCALE_CHOICE_SINGLE,
   data: reqData
});

const clearScaleChoiceSRAction = (reqData) => ({
   type: CLEAR_SCALE_CHOICE_SINGLE,
   data: reqData
});

const setScoreSRAction = (reqData) => ({
   type: SET_SCORE_SINGLE,
   data: reqData
});

const setParticipantSRAction = (reqData) => ({
   type: SET_PARTICIPANT_SINGLE,
   data: reqData
});

const deleteParticipantSRAction = (reqData) => ({
   type: DELETE_PARTICIPANT_SINGLE,
   data: reqData
});

const editParticipantSRAction = (reqData) => ({
   type: EDIT_PARTICIPANT_SINGLE,
   data: reqData
});

const setEditParticipantViewSR = (reqData) => ({
   type: SET_VIEW_EDIT_PARTICIPANT_SINGLE,
   data: reqData
});

const resetViewTabSR = () => ({
   type: RESET_VIEW_TAB_SINGLE
});

const resetSingleRater = () => ({
   type: RESET_SINGLE_RATER
});

const resetSettingSR = () => ({
   type: RESET_SETTING_SINGLE
});

const resetSectionSR = () => ({
   type: RESET_SECTION_SINGLE
});

const resetContentViewSR = () => ({
   type: RESET_CONTENT_VIEW_SINGLE
});

const resetScaleChoiceSR = () => ({
   type: RESET_SCALE_CHOICE_SINGLE
});

const resetScoreSR = () => ({
   type: RESET_SCORE_SINGLE
});

const resetParticipantSR = () => ({
   type: RESET_PARTICIPANT_SINGLE
});

const resetViewEditParticipantSR = () => ({
   type: RESET_VIEW_EDIT_PARTICIPANT_SINGLE
});

const duplicateSRAction = (reqData) => ({
   type: DUPLICATE_SINGLE_RATER,
   data: reqData
});

const duplicateSettingSRAction = (reqData) => ({
   type: DUPLICATE_SETTING_SR,
   data: reqData
});

const duplicateSectionContentSRAction = (reqData) => ({
   type: DUPLICATE_SECTION_CONTENT_SR,
   data: reqData
});

const duplicateScaleChoiceSRAction = (reqData) => ({
   type: DUPLICATE_SCALE_CHOICE_SR,
   data: reqData
});

const duplicateScoreSRAction = (reqData) => ({
   type: DUPLICATE_SCORE_SR,
   data: reqData
});

const duplicateParticipantSRAction = (reqData) => ({
   type: DUPLICATE_PARTICIPANT_SR,
   data: reqData
});

const clearDataPreviewSR = () => ({
   type: CLEAR_DATA_PREVIEW_SR
});

const setDataPreviewSR = (reqData) => ({
   type: SET_DATA_PREVIEW_SR,
   data: reqData
});

const setValidSettingSRAction = (reqData) => ({
   type: SET_VALID_SETTING,
   data: reqData
});

const setValidContentSRAction = (reqData) => ({
   type: SET_VALID_CONTENT,
   data: reqData
});

const setValidParticipantSRAction = (reqData) => ({
   type: SET_VALID_PARTICIPANT,
   data: reqData
});

const resetValidInputSRAction = (reqData) => ({
   type: RESET_VALID_INPUT,
   data: reqData
});

const setEditParticipantViewByEmailSR = (reqData) => ({
   type: SET_VIEW_EDIT_PARTICIPANT_SR_KEY_EMAIL,
   data: reqData
});

const resetViewEditParticipantByEmailSR = () => ({
   type: RESET_VIEW_EDIT_PARTICIPANT_SR_KEY_EMAIL
});


export default {
   setSRViewAction,
   setSRAction,
   setSettingSRAction,
   setSectionSRAction,
   editSectionSRAction,
   deleteSectionSRAction,
   setContentSRAction,
   editContentSRAction,
   deleteContentSRAction,
   setContentChoiceSRAction,
   setContentChoiceScaleSRAction,
   editContentChoiceSRAction,
   deleteContentChoiceSRAction,
   setContentViewSRAction,
   clearContentViewSRAction,
   setScaleChoiceSRAction,
   addScaleChoiceSRAction,
   editScaleChoiceSRAction,
   deleteScaleChoiceSRAction,
   clearScaleChoiceSRAction,
   setScoreSRAction,
   setParticipantSRAction,
   deleteParticipantSRAction,
   editParticipantSRAction,
   setEditParticipantViewSR,
   resetViewTabSR,
   resetSingleRater,
   resetSettingSR,
   resetSectionSR,
   resetContentViewSR,
   resetScaleChoiceSR,
   resetScoreSR,
   resetParticipantSR,
   resetViewEditParticipantSR,
   setContentBulkSRAction,
   setDataPreviewSR,
   clearDataPreviewSR,
   duplicateSRAction,
   duplicateSettingSRAction,
   duplicateSectionContentSRAction,
   duplicateScaleChoiceSRAction,
   duplicateScoreSRAction,
   duplicateParticipantSRAction,
   setValidSettingSRAction,
   setValidContentSRAction,
   setValidParticipantSRAction,
   resetValidInputSRAction,
   setEditParticipantViewByEmailSR,
   resetViewEditParticipantByEmailSR
}