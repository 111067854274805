import { useEffect, useState } from "react";
import { useDeps } from "../../../../../../shared/context/DependencyContext";
import screenToast from "../../../../../../shared/hook/ScreenToast";

function useSelectRatersInternal({limit, pageNumber, search, buName, deptName, level}) {
   const {generalService} = useDeps();
    const [buData, setBuData] = useState([{ business_unit_name: "ALL", business_unit_code: "11111111" }]);
    const [departmentData, setDepartmentData] = useState([{ department_name: "ALL", department_code: "11111111" }]);
    const [levelData, setLevelData] = useState([{ level_user: "ALL" }]);
    const [employeeData, setEmployeeData] = useState([]);
    const [totalItem, setTotalItem] = useState(0);
   const showToast = screenToast();


    useEffect(() => {
        onGetBUData();
        onGetDepartmentData();
        onGetLevelData();
    }, []);

    useEffect(() => {
        let filterData = {
           limit: limit,
           page_number: pageNumber,
           search: search,
           level: level == 'ALL' ? '' : level,
           bu_name: buName == 'ALL' ? '' : buName,
           dept_name: deptName == 'ALL' ? '' : deptName
        }
  
        onFilterRaters(filterData);
     }, [limit, pageNumber, search, buName, deptName, level]);

     const onFilterRaters = async (filterData) => {
        try {
           const response = await generalService.generalDataService.getUserMaster(filterData);
  
           if (response.status == 'SUCCESS') {
              if (response.data != 'NO DATA') {
                 setTotalItem(response.data.total_item);
                 setEmployeeData(response.data.data);
              } else {
                 showToast.warning("No data found")
              }
           } else if (response.status == 'UNKNOWN ERROR') {
              setTotalItem(0);
              setEmployeeData([]);
           }
        } catch (error) {
           console.log(error)
        }
     }
  
     const onGetBUData = async () => {
        try {
           const response = await generalService.generalDataService.getBusinessUnitData();
  
           if (response.status == 'SUCCESS') {
              setBuData([...buData, ...response.data]);
           } else {
              setBuData([]);
           }
        } catch (error) {
           console.log(error)
        }
     }
  
     const onGetDepartmentData = async () => {
        try {
           const response = await generalService.generalDataService.getDepartmentData();
  
           if (response.status == 'SUCCESS') {
              setDepartmentData([...departmentData, ...response.data]);
           } else {
              setDepartmentData([]);
           }
        } catch (error) {
           console.log(error)
        }
     }
  
     const onGetLevelData = async () => {
        try {
           const response = await generalService.generalDataService.getLevelData();
  
           if (response.status == 'SUCCESS') {
              setLevelData([...levelData, ...response.data]);
           } else {
              setLevelData([]);
           }
        } catch (error) {
           console.log(error)
        }
     }
  
     return {
        employeeData,
        totalItem,
        buData,
        departmentData,
        levelData
     }
}

export default useSelectRatersInternal