import { useEffect, useState } from "react";
import { DropdownWithLabel } from "../../../../shared/component/dropdown/DropdownInput";
import { InputOnlyForDropdown, InputTextWithLabel, InputWithLabel, TextAreaWithLabel } from "../../../../shared/component/input/Input";
import { Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import useTabSettings from "./useTabSettings";
import useRefs from "react-use-refs";
import { itemLanguage, loginAccess, participantAvailability, reminderInterval, statusSurvey } from "./TabSettingData";
import { rootAction } from "../../../../redux/RootAction";

function TabSettings() {
    const [surveyGoaRef, buNameRef, deptNameRef, languageRef, reminderRef, participantAvailRef, statusRef, loginAccessRef] = useRefs();
    const { goalData, buData, departmentData } = useTabSettings();
    const [searchDept, setSearchDept] = useState('');
    const [filterDept, setFilteredDept] = useState(departmentData);
    const [searchBU, setSearchBU] = useState('');
    const [filterBU, setFilteredBU] = useState(buData);
    const settingReducer = useSelector((state) => state.setting_multi_rater);
    const multiRaterReducer = useSelector((state) => state.multi_rater);
    const userReducer = useSelector((state) => state.user);
    const dispatch = useDispatch();

    // handler
    const handleSearchDept = (e) => {
        const term = e.target.value;
        setSearchDept(term);

        if (departmentData.length != 0) {
            const filtered = departmentData.filter((option) =>
                option.department_name != null ? option.department_name.toString().toLowerCase().includes(term.toLowerCase()) : ''
            );

            setFilteredDept(filtered);
        }
    };

    const handleSearchBU = (e) => {
        const term = e.target.value;
        setSearchBU(term);
        if (buData.length != 0) {
            const filtered = buData.filter((option) =>
                option.business_unit_name != null ? option.business_unit_name.toString().toLowerCase().includes(term.toLowerCase()) : ''
            );

            setFilteredBU(filtered);
        }
    };

    const handleOnChangeText = (e) => {
        dispatch(rootAction.multiRater.setSettingMRAction({
            field: e.target.id,
            payload: e.target.value
        }));
    }

    const handleOnSelect = (ref, val) => {
        if (ref.current.id == 'is_active' ) {
            dispatch(rootAction.multiRater.setMRAction({
                field: ref.current.id,
                payload: val
            }));
        } else {
            dispatch(rootAction.multiRater.setSettingMRAction({
                field: ref.current.id,
                payload: val
            }));
        }
    }


    // effect
    useEffect(() => {
        dispatch(rootAction.multiRater.setMRAction({
            field: 'type',
            payload: 'multi'
        }));

        dispatch(rootAction.multiRater.setMRAction({
            field: 'created_by',
            payload: userReducer.email
        }));
    }, []);

    useEffect(() => {
        setFilteredDept(departmentData);
        setFilteredBU(buData);
    }, [departmentData, buData]);

    return (
        <div className="col-12 d-flex">
            <div className="col-6 pe-4">
                <div className="col-12" style={{ borderBottom: '0.1rem solid black' }}>
                    <h5>Metadata</h5>
                </div>

                <div className="col-12 my-4">
                    <InputTextWithLabel id={'survey_name'} label={'Name'} label_width={'10vw'} handleOnChange={handleOnChangeText} value={settingReducer.survey_name} required={true} />
                </div>
                <div className="col-12 my-4">
                    <TextAreaWithLabel id={'description_setting'} label={'Description'} input_height={'20vh'} label_width={'10vw'} handleOnChange={handleOnChangeText} value={settingReducer.description_setting} required={true} />
                </div>
                <div className="col-12 my-4">
                    <DropdownWithLabel
                        id={'goal_id'}
                        required={true}
                        innerRef={surveyGoaRef}
                        label={'Goal'}
                        label_width={'10vw'}
                        default_item={(settingReducer.goal_id && Array.isArray(goalData) && goalData.length > 0) ? goalData.find((el) => el.id === settingReducer.goal_id)?.goal_name : null}
                        dropdown_item={
                            Array.isArray(goalData) && goalData.length != 0 ? (goalData.map((itm, i) => {
                                return (
                                    <Dropdown.Item key={i} onClick={() => handleOnSelect(surveyGoaRef, itm.id)} >{itm.goal_name}</Dropdown.Item>
                                )
                            })
                            ) :
                                (<Dropdown.Item>Select</Dropdown.Item>)
                        }
                    />
                </div>
                <div className="col-12 my-4">
                    <DropdownWithLabel
                        id={'business_unit_code'}
                        required={true}
                        innerRef={buNameRef}
                        label={'Business Unit'}
                        label_width={'10vw'}
                        default_item={(settingReducer.business_unit_code && Array.isArray(buData) && buData.length > 1) ? buData.find((el) => el.business_unit_code === settingReducer.business_unit_code)?.business_unit_name : null}
                        search_bar={
                            <InputOnlyForDropdown
                                type="text"
                                placeholder="Search Business Unit"
                                value={searchBU}
                                handleOnChange={handleSearchBU}
                                input_width={'100%'}
                            />
                        }
                        dropdown_item={
                            Array.isArray(filterBU) && filterBU.length != 0 ? (filterBU.map((itm, i) => {
                                return (
                                    <Dropdown.Item key={i} onClick={() => handleOnSelect(buNameRef, itm.business_unit_code)}>{itm.business_unit_name}</Dropdown.Item>
                                )
                            })
                            ) :
                                (<Dropdown.Item>Select</Dropdown.Item>)
                        }
                    />
                </div>
                <div className="col-12 my-4">
                    <DropdownWithLabel
                        id={'department_code'}
                        required={true}
                        innerRef={deptNameRef}
                        label={'Department'}
                        label_width={'10vw'}
                        default_item={(settingReducer.department_code && Array.isArray(departmentData) && departmentData.length > 1) ? departmentData.find((el) => el.department_code === settingReducer.department_code)?.department_name : null}
                        search_bar={
                            <InputOnlyForDropdown
                                type="text"
                                placeholder="Search Department "
                                value={searchDept}
                                handleOnChange={handleSearchDept}
                                input_width={'100%'}
                            />
                        }
                        dropdown_item={
                            Array.isArray(filterDept) && filterDept.length != 0 ? (filterDept.map((itm, i) => {
                                return (
                                    <Dropdown.Item key={i} onClick={() => handleOnSelect(deptNameRef, itm.department_code)}>{itm.department_name}</Dropdown.Item>
                                )
                            })
                            ) :
                                (<Dropdown.Item>Select</Dropdown.Item>)
                        }
                    />
                </div>
                <div className="col-12 my-4">
                    <DropdownWithLabel
                        id={'language'}
                        required={true}
                        innerRef={languageRef}
                        label={'Item Language'}
                        label_width={'10vw'}
                        default_item={settingReducer.language ? itemLanguage.find((el) => el.val === settingReducer.language).language : null}
                        dropdown_item={
                            itemLanguage.length != 0 ? (itemLanguage.map((itm, i) => {
                                return (
                                    <Dropdown.Item key={i} onClick={() => handleOnSelect(languageRef, itm.val)}>{itm.language}</Dropdown.Item>
                                )
                            })
                            ) :
                                (<Dropdown.Item>Select</Dropdown.Item>)
                        }
                    />
                </div>
                <div className="col-12 my-4">
                    <DropdownWithLabel
                        id={'participant_availability'}
                        required={true}
                        innerRef={participantAvailRef}
                        label={'Participant Availability'}
                        label_width={'10vw'}
                        default_item={settingReducer.participant_availability ? participantAvailability.find((el) => el.val === settingReducer.participant_availability)?.text : null}
                        dropdown_item={
                            participantAvailability.length != 0 ? (participantAvailability.map((el, i) => {
                                return (
                                    <Dropdown.Item key={i} onClick={() => handleOnSelect(participantAvailRef, el.val)}>{el.text}</Dropdown.Item>
                                )
                            })
                            ) :
                                (<Dropdown.Item>Select</Dropdown.Item>)
                        }
                    />
                </div>

                <div className="col-12 my-4">
                    <TextAreaWithLabel id={'welcome_text'} label={'Welcome Text'} input_height={'20vh'} label_width={'10vw'} handleOnChange={handleOnChangeText} value={settingReducer.welcome_text} required={true} />
                </div>

                <div className="col-12 my-4">
                    <TextAreaWithLabel id={'finish_text'} label={'Finish Text'} input_height={'20vh'} label_width={'10vw'} handleOnChange={handleOnChangeText} value={settingReducer.finish_text} required={true} />
                </div>
            </div>

            <div className="col-6 ps-4">
                <div className="col-12" style={{ borderBottom: '0.1rem solid black' }}>
                    <h5>Invitation</h5>
                </div>
                <div className="col-12 my-4">
                    <TextAreaWithLabel id={'invitation_text'} label={'Invitation Text'} input_height={'20vh'} label_width={'10vw'} handleOnChange={handleOnChangeText} value={settingReducer.invitation_text} required={true} />
                </div>
                <div className="col-12 my-4">
                    <TextAreaWithLabel id={'reminder_text'} label={'Reminder Text'} input_height={'20vh'} label_width={'10vw'} handleOnChange={handleOnChangeText} value={settingReducer.reminder_text} required={true} />
                </div>
                <div className="col-12 my-4">
                    <DropdownWithLabel
                        id={'reminder_interval_in_days'}
                        required={true}
                        innerRef={reminderRef}
                        label={'Automatic Reminder'}
                        label_width={'10vw'}
                        default_item={settingReducer.reminder_interval_in_days !== undefined ? reminderInterval.find((el) => el.val === settingReducer.reminder_interval_in_days).text : null}
                        dropdown_item={
                            reminderInterval.length != 0 ? (reminderInterval.map((itm, i) => {
                                return (
                                    <Dropdown.Item key={i} onClick={() => handleOnSelect(reminderRef, itm.val)}>{itm.text}</Dropdown.Item>
                                )
                            })
                            ) :
                                (<Dropdown.Item>Select</Dropdown.Item>)
                        }
                    />
                </div>
                <div className="col-12" style={{ borderBottom: '0.1rem solid black' }} >
                    <h5>Valid Periode</h5>
                </div>
                <div className="col-12 my-4">
                    <DropdownWithLabel
                        id={'is_active'}
                        innerRef={statusRef}
                        label={'Status'}
                        label_width={'10vw'}
                        default_item={multiRaterReducer.is_active !== undefined ? statusSurvey.find((el) => el.id === multiRaterReducer.is_active).text : null}
                        dropdown_item={
                            statusSurvey.length != 0 ? (statusSurvey.map((itm, i) => {
                                return (
                                    <Dropdown.Item key={i} onClick={() => handleOnSelect(statusRef, itm.id)}>{itm.text}</Dropdown.Item>
                                )
                            })
                            ) :
                                (<Dropdown.Item>Select</Dropdown.Item>)
                        }
                    />
                </div>
                <div className="col-12 my-4">
                    <InputWithLabel id={'start_date'} label={'Start Date'} label_width={'10vw'} type={'date'} value={settingReducer.start_date} handleOnChange={handleOnChangeText} />
                </div>
                <div className="col-12 my-4">
                    <InputWithLabel id={'end_date'} label={'End Date'} label_width={'10vw'} type={'date'} value={settingReducer.end_date} handleOnChange={handleOnChangeText} />
                </div>
                <div className="col-12" style={{ borderBottom: '0.1rem solid black' }} >
                    <h5>Others</h5>
                </div>
                <div className="col-12 my-4">
                    <DropdownWithLabel
                        id={'is_auto_login'}
                        innerRef={loginAccessRef}
                        label={'Login Access'}
                        label_width={'10vw'}
                        default_item={settingReducer.is_auto_login !== undefined ? loginAccess.find((el) => el.id === settingReducer.is_auto_login).text : null}
                        dropdown_item={
                            loginAccess.length != 0 ? (loginAccess.map((itm, i) => {
                                return (
                                    <Dropdown.Item key={i} onClick={() => handleOnSelect(loginAccessRef, itm.id)}>{itm.text}</Dropdown.Item>
                                )
                            })
                            ) :
                                (<Dropdown.Item>Select</Dropdown.Item>)
                        }
                    />
                </div>
            </div>
        </div>
    )
}

export default TabSettings;

