import { ADD_CONTENT_MANDATORY, CLEAR_DATA_PARTICIPANT_RATER_SURVEY_FORM, CLEAR_SURVEY_FIELD, DELETE_ALL_MANDATORY_CONTENT, DELETE_ALL_MANY_ANSWER, DELETE_MANDATORY_CONTENT, DELETE_MANY_ANSWER, DELETE_MR_SURVEY_DETAIL_DATA, EDIT_MANY_ANSWER, EDIT_MANY_ANSWER_SAME_CONTENT, SET_DATA_PARTICIPANT_RATER_SURVEY_FORM, SET_MANY_ANSWER, SET_MR_SURVEY_DETAIL_DATA, SET_ONE_ANSWER, SET_SURVEY_FIELD } from "./MultiraterFormPartType";

const initOneAnswer = {
    participant_nik:"",
    participant_bu:"",
    participant_dept:"",
    participant_email:"",
    s_raters_id: 0,
    s_participant_id: 0,
    target_nik:"",
    target_email:"",
    survey_code:"",
    survey_name:"",
    section_code:"",
    section_name:"",
    content_code:"",
    content_name:"",
    content_type:"",
    choice_code:"",
    choice_text:"",
    choice_score:0,
    scale_display:"",
    is_mandatory: "false",
    answer:"",
    goal_id:"",
    created_at:"",
}

const initSurveyField = {
    participant_nik:"",
    participant_bu:"",
    participant_dept:"",
    participant_email:"",
    s_raters_id: 0,
    s_participant_id: 0,
    target_nik:"",
    target_email:"",
    survey_code:"",
    survey_name:"",
}

const initDataParticipantRaterMRForm = {
    s_raters_id: 0,
    s_participant_id: 0,
    survey_code: "",
    target_nik: "",
    target_full_name: "",
    target_email: "",
    survey_name: "",
    rater_role: "",
    rater_nik:"",
    rater_email:"",
    rater_bu: "",
    rater_dept: "",
}

const initInsertManyAnswer = [];

const initContentMandatoryList = [];

const initMRDetailData = {}

export function oneAnswerSurveyFormReducer(state = initOneAnswer, action) {
    switch (action.type) {
        case SET_ONE_ANSWER:
            return {
                ...state,
                [action.data.field]: action.data.payload
            }
    
        default:
            return state;
    }
}

export function surveyFormDataReducer(state = initSurveyField, action) {
    switch (action.type) {
        case SET_SURVEY_FIELD:
            return {
                ...state,
                [action.data.field]: action.data.payload
            }
        case CLEAR_SURVEY_FIELD:
            return initSurveyField
        default:
            return state;
    }
}

export function manyAnswerSurveyFormReducer(state = initInsertManyAnswer, action) {
    switch (action.type) {
       case SET_MANY_ANSWER:
          return [...state, action.data];
       case EDIT_MANY_ANSWER:
          return [...state].map((el) => {

             if (el.section_code == action.data.key_section && el.content_code == action.data.key_content) {
               
                return {
                   ...el,
                   [action.data.field]: action.data.payload
                }
             } else {
                
                return el;
             }
          });
       case DELETE_MANY_ANSWER:
        return state.filter((el) => el.section_code != action.data.key_section || el.content_code != action.data.key_content || el.choice_code != action.data.key_choice);
       case DELETE_ALL_MANY_ANSWER:
        // console.log('delete all answer');
        return initInsertManyAnswer
       case EDIT_MANY_ANSWER_SAME_CONTENT:
        return [...state].map((el) => {

            if (el.section_code == action.data.key_section && el.content_code == action.data.key_content && el.choice_code == action.data.key_choice) {
              
               return {
                  ...el,
                  [action.data.field]: action.data.payload
               }
            } else {
               
               return el;
            }
         });

        default:
          return state;
    }
 }

 export function dataParticipantRaterMRFormReducer(state = initDataParticipantRaterMRForm, action) {
    switch (action.type) {
        case SET_DATA_PARTICIPANT_RATER_SURVEY_FORM:
            return {
                ...state,
                [action.data.field]: action.data.payload
            }
        case CLEAR_DATA_PARTICIPANT_RATER_SURVEY_FORM:
            return initDataParticipantRaterMRForm
        default:
            return state;
    }
}

export function contentMandatoryList(state = initContentMandatoryList, action) {
    switch (action.type) {
        case ADD_CONTENT_MANDATORY:
            return [...state, action.data];
        case DELETE_MANDATORY_CONTENT:
            const res = state.filter((el) => el.section_code != action.data.section_code || el.content_code != action.data.content_code );
            return res
        case DELETE_ALL_MANDATORY_CONTENT:
            return initContentMandatoryList
        default:
            return state;
    }
}

export function SurveyMRDetailData(state =  initMRDetailData, action) {
    switch (action.type) {
        case SET_MR_SURVEY_DETAIL_DATA:
            return action.data;
        case DELETE_MR_SURVEY_DETAIL_DATA:
            return initMRDetailData
        default:
            return state;
    }
}

