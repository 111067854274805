import { ADD_SCALE_CHOICE, CLEAR_SCALE_CHOICE, CLEAR_VIEW_CONTENT_UPDATE, DELETE_CONTENT, DELETE_CONTENT_CHOICE, DELETE_OWNERSHIP_MR, DELETE_PARTICIPANT, DELETE_SCALE_CHOICE, DELETE_SECTION, EDIT_CONTENT, EDIT_CONTENT_CHOICE, EDIT_PARTICIPANT, EDIT_SCALE_CHOICE, EDIT_SECTION, LOAD_MULTI_RATER, LOAD_PARTICIPANT, LOAD_SCALE_CHOICE, LOAD_SCORE, LOAD_SECTION_CONTENT, LOAD_SETTING, OWNERSHIP_LIST_MR, RESET_CONTENT_VIEW, RESET_IS_EDITING, RESET_MULTI_RATER, RESET_OWNERSHIP_MR, RESET_PARTICIPANT, RESET_SCALE_CHOICE, RESET_SCORE, RESET_SECTION, RESET_SETTING, RESET_VALID_INPUT, RESET_VIEW_EDIT_PARTICIPANT, RESET_VIEW_EDIT_PARTICIPANT_KEY_EMAIL, RESET_VIEW_TAB, SET_CONTENT, SET_CONTENT_BULK, SET_CONTENT_CHOICE, SET_CONTENT_CHOICE_SCALE, SET_IS_EDITING, SET_MULTI_RATER, SET_PARTICIPANT, SET_SCALE_CHOICE, SET_SCORE, SET_SECTION, SET_SETTING, SET_VALID_CONTENT, SET_VALID_PARTICIPANT, SET_VALID_SETTING, SET_VIEW, SET_VIEW_CONTENT_UPDATE, SET_VIEW_EDIT_PARTICIPANT, SET_VIEW_EDIT_PARTICIPANT_KEY_EMAIL } from "./UpdateMultiRaterType"

// initial state
const initViewTabState = 1;

const initViewEditParticipantState = '';

const initMultiRaterState = {}

const initSettingState = {
   survey_name: '',
   description_setting: '',
   anonimity: 0,
   language: '',
   finish_text: '',
   invitation_text: '',
   reminder_text: '',
   reminder_interval_in_days: 0,
   goal_id: 0,
   business_unit_code: '',
   department_code: '',
   pagination_content: '',
   welcome_text: '',
   start_date: '',
   end_date: '',
   participant_availability: ''
}

const initParticipantState = [];

const initSectionState = [];

const initContentViewState = {
   section_id: 0,
   content_id: 0
}

const initScaleChoiceState = []

const initScoreState = {
   supervisor_weight: 0,
   subordinate_weight: 0,
   peer_weight: 0,
   stakeholder_weight: 0,
   self_weight:0
}

const initIsEditingState = false;

const initIsValid = {
   setting: true,
   content: true,
   participant: true
}

const initOwnership = []

// define reducer action
export function updateViewMultiRaterReducer(state = initViewTabState, action) {
   switch (action.type) {
      case SET_VIEW:
         return state = action.data;
      case RESET_VIEW_TAB:
         return state = 1;
      default:
         return state;
   }
}

export function updateMultiRaterReducer(state = initMultiRaterState, action) {
   switch (action.type) {
      case LOAD_MULTI_RATER:
         return Object.assign({}, state, action.data);
      case SET_MULTI_RATER:
         return {
            ...state,
            [action.data.field]: action.data.payload
         }
      case RESET_MULTI_RATER:
         return state = {}
      default:
         return state;
   }
}

export function updateSettingMultiRaterReducer(state = initSettingState, action) {
   switch (action.type) {
      case LOAD_SETTING:
         return Object.assign({}, state, action.data);
      case SET_SETTING:
         return {
            ...state,
            [action.data.field]: action.data.payload
         }
      case RESET_SETTING:
         return state = {
            survey_name: '',
            description_setting: '',
            anonimity: 0,
            language: '',
            finish_text: '',
            invitation_text: '',
            reminder_text: '',
            reminder_interval_in_days: 0,
            goal_id: 0,
            business_unit_code: '',
            department_code: '',
            pagination_content: '',
            welcome_text: '',
            start_date: '',
            end_date: '',
            participant_availability: ''
         }
      default:
         return state;
   }
}

export function updateSectionMultiRaterReducer(state = initSectionState, action) {
   switch (action.type) {
      case LOAD_SECTION_CONTENT:
         return [...action.data];
      case SET_SECTION:
         return [...state, action.data];
      case EDIT_SECTION:
         return [...state].map((el) => {
            if (el.section_id == action.data.key) {
               return {
                  ...el,
                  [action.data.field]: action.data.payload
               }
            } else {
               return el;
            }
         });
      case DELETE_SECTION:
         return state.filter((el) => el.section_id != action.data);
      case SET_CONTENT:
         return [...state].map((el) => {
            if (el.section_id == action.data.key) {
               return {
                  ...el,
                  content: [...el.content, action.data.payload]
               };
            } else {
               return el;
            }
         });
      case SET_CONTENT_BULK:
         return [...state].map((el) => {
            if (el.section_id == action.data.key) {
               return {
                  ...el,
                  content: action.data.payload
               };
            } else {
               return el;
            }
         });
      case EDIT_CONTENT:
         return [...state].map((el) => {
            if (el.section_id == action.data.section_key) {
               return {
                  ...el,
                  content: [...el.content].map((val) => {
                     if (val.content_id == action.data.content_key) {
                        return {
                           ...val,
                           [action.data.field]: action.data.payload
                        }
                     } else {
                        return val;
                     }
                  })
               }
            } else {
               return el;
            }
         });
      case DELETE_CONTENT:
         return [...state].map((el) => {
            if (el.section_id == action.data.section_key) {
               return {
                  ...el,
                  content: el.content.filter((el) => el.content_id != action.data.content_key).map((val, i) => {
                     if (val.content_id != action.data.content_key) {
                        return {
                           ...val,
                           content_id: i + 1,
                           content_name: 'Question ' + (i + 1)
                        }
                     }
                  })
               }
            } else {
               return el;
            }
         });
      case SET_CONTENT_CHOICE:
         return [...state].map((el) => {
            if (el.section_id == action.data.section_key) {
               return {
                  ...el,
                  content: [...el.content].map((val) => {
                     if (val.content_id == action.data.content_key) {
                        return {
                           ...val,
                           content_choice: [...val.content_choice, action.data.payload]
                        }
                     } else {
                        return val;
                     }
                  })
               }
            } else {
               return el;
            }
         });
      case SET_CONTENT_CHOICE_SCALE:
         return [...state].map((el) => {
            if (el.section_id == action.data.section_key) {
               return {
                  ...el,
                  content: [...el.content].map((val) => {
                     if (val.content_id == action.data.content_key) {
                        return {
                           ...val,
                           content_choice: action.data.payload
                        }
                     } else {
                        return val;
                     }
                  })
               }
            } else {
               return el;
            }
         });
      case EDIT_CONTENT_CHOICE:
         return [...state].map((el) => {
            if (el.section_id == action.data.section_key) {
               return {
                  ...el,
                  content: [...el.content].map((val) => {
                     if (val.content_id == action.data.content_key) {
                        return {
                           ...val,
                           content_choice: [...val.content_choice].map((data) => {
                              if (data.choice_id == action.data.choice_key) {
                                 return {
                                    ...data,
                                    [action.data.field]: action.data.payload
                                 }
                              } else {
                                 return data;
                              }
                           })
                        }
                     } else {
                        return val;
                     }
                  })
               }
            } else {
               return el;
            }
         })
      case DELETE_CONTENT_CHOICE:
         return [...state].map((el) => {
            if (el.section_id == action.data.section_key) {
               return {
                  ...el,
                  content: [...el.content].map((val) => {
                     if (val.content_id == action.data.content_key) {
                        return {
                           ...val,
                           content_choice: val.content_choice.filter((data) => data.choice_id != action.data.choice_key)
                        }
                     } else {
                        return val;
                     }
                  })
               }
            } else {
               return el;
            }
         });
      case RESET_SECTION:
         return state = [];
      default:
         return state;
   }
}

export function updateViewContentMultiRaterReducer(state = initContentViewState, action) {
   switch (action.type) {
      case SET_VIEW_CONTENT_UPDATE:
         return Object.assign({}, state, action.data);
      case CLEAR_VIEW_CONTENT_UPDATE:
         return Object.assign({}, state, {
            section_id: 0,
            content_id: 0
         });
      case RESET_CONTENT_VIEW:
         return state = {
            section_id: 0,
            content_id: 0
         }
      default:
         return state;
   }
}

export function updateScaleChoiceMultiRaterReducer(state = initScaleChoiceState, action) {
   switch (action.type) {
      case LOAD_SCALE_CHOICE:
         return [...action.data];
      case SET_SCALE_CHOICE:
         return [...state, action.data];
      case ADD_SCALE_CHOICE:
         return [...state].map((el) => {
            if (el.section_id == action.data.key) {
               return {
                  ...el,
                  content_choice: [...el.content_choice, action.data.payload]
               }
            } else {
               return el;
            }
         })
      case EDIT_SCALE_CHOICE:
         return [...state].map((el) => {
            if (el.section_id == action.data.section_key) {
               return {
                  ...el,
                  content_choice: [...el.content_choice].map((val) => {
                     if (val.scale_id == action.data.scale_key) {
                        return {
                           ...val,
                           [action.data.field]: action.data.payload
                        }
                     } else {
                        return val;
                     }
                  })
               }
            } else {
               return el;
            }
         });
      case DELETE_SCALE_CHOICE:
         return [...state].map((el) => {
            if (el.section_id == action.data.section_key) {
               let newScale = {
                  ...el,
                  content_choice: el.content_choice.filter((val) => val.scale_id != action.data.scale_key)
               }

               return {
                  ...newScale,
                  content_choice: [...newScale.content_choice].map((val, i) => {
                     return {
                        ...val,
                        choice_score: i + 1
                     }
                  })
               };
            } else {
               return el;
            }
         })
      case CLEAR_SCALE_CHOICE:
         return state.filter((el) => el.section_id != action.data);
      case RESET_SCALE_CHOICE:
         return state = [];
      default:
         return state;
   }
}

export function updateScoreMultiRaterReducer(state = initScoreState, action) {
   switch (action.type) {
      case LOAD_SCORE:
         return Object.assign({}, state, action.data);
      case SET_SCORE:
         return {
            ...state,
            [action.data.field]: action.data.payload
         }
      case RESET_SCORE:
         return state = {
            supervisor_weight: 0,
            subordinate_weight: 0,
            peer_weight: 0,
            stakeholder_weight: 0,
            self_weight:0
         }
      default:
         return state;
   }
}

export function updateParticipantMultiRaterReducer(state = initParticipantState, action) {
   switch (action.type) {
      case LOAD_PARTICIPANT:
         return [...action.data];
      case SET_PARTICIPANT:
         return action.data;
      case EDIT_PARTICIPANT:
         return [...state].map((el) => {
            if (el.nik == action.data.nik) {
               return action.data;
            } else {
               return el;
            }
         });
      case DELETE_PARTICIPANT:
         return state.filter((el) => el.nik != action.data);
      case RESET_PARTICIPANT:
         return state = [];
      default:
         return state;
   }
}

export function updateViewEditParticipantReducer(state = initViewEditParticipantState, action) {
   switch (action.type) {
      case SET_VIEW_EDIT_PARTICIPANT:
         return action.data;
      case RESET_VIEW_EDIT_PARTICIPANT:
         return state = '';
      default:
         return state;
   }
}

export function updateIsEditingReducer(state = initIsEditingState, action) {
   switch (action.type) {
      case SET_IS_EDITING:
         return state = action.data;
      case RESET_IS_EDITING:
         return state = false;
      default:
         return state;
   }
}

export function updateIsValidMultiRaterReducer(state = initIsValid, action) {
   switch (action.type) {
      case SET_VALID_SETTING:
         return state = {
            ...state,
            setting: action.data
         };
      case SET_VALID_CONTENT:
         return state = {
            ...state,
            content: action.data
         };
      case SET_VALID_PARTICIPANT:
         return state = {
            ...state,
            participant: action.data
         };
      case RESET_VALID_INPUT:
         return state = {
            setting: true,
            content: true,
            participant: true
         };
      default:
         return state;
   }
}

export function ownershipMRReducer(state = initOwnership, action) {
   switch (action.type) {
      case OWNERSHIP_LIST_MR:
         return action.data;
      case DELETE_OWNERSHIP_MR:
         return state.filter((el) => el.email != action.data);
      case RESET_OWNERSHIP_MR:
         return initOwnership;
      default:
         return state;
   }
}

export function updateViewEditParticipantReducerByEmail(state = initViewEditParticipantState, action) {
   switch (action.type) {
      case SET_VIEW_EDIT_PARTICIPANT_KEY_EMAIL:
         return action.data;
      case RESET_VIEW_EDIT_PARTICIPANT_KEY_EMAIL:
         return state = '';
      default:
         return state;
   }
}