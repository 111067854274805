export const SET_SETTING = 'set_setting';
export const SET_MULTI_RATER = 'set_multi_rater';
export const SET_VIEW = 'set_view';
export const SET_SECTION = 'set_section';
export const EDIT_SECTION = 'edit_section';
export const DELETE_SECTION = 'delete_section';
export const SET_CONTENT = 'set_content';
export const EDIT_CONTENT = 'edit_content';
export const DELETE_CONTENT = 'delete_content';
export const SET_CONTENT_CHOICE = 'set_content_choice';
export const SET_CONTENT_CHOICE_SCALE = 'set_content_choice_scale';
export const EDIT_CONTENT_CHOICE = 'edit_content_choice';
export const DELETE_CONTENT_CHOICE = 'delete_content_choice';
export const SET_VIEW_CONTENT = 'set_view_content';
export const CLEAR_VIEW_CONTENT = 'clear_view_content';
export const SET_SCALE_CHOICE = 'set_scale_choice';
export const ADD_SCALE_CHOICE = 'add_scale_choice';
export const EDIT_SCALE_CHOICE = 'edit_scale_choice';
export const DELETE_SCALE_CHOICE = 'delete_scale_choice';
export const CLEAR_SCALE_CHOICE = 'clear_scale_choice';
export const SET_SCORE = 'set_score';
export const SET_PARTICIPANT = 'set_participant';
export const DELETE_PARTICIPANT = 'delete_participant';
export const EDIT_PARTICIPANT = 'edit_participant';
export const SET_VIEW_EDIT_PARTICIPANT = 'set_view_edit_participant';
export const RESET_VIEW_TAB = 'reset_view_tab';
export const RESET_MULTI_RATER = 'reset_multi_rater';
export const RESET_SETTING = 'reset_setting';
export const RESET_SECTION = 'reset_section';
export const RESET_CONTENT_VIEW = 'reset_content_view';
export const RESET_SCALE_CHOICE = 'reset_scale_choice';
export const RESET_SCORE = 'reset_score';
export const RESET_PARTICIPANT = 'reset_participant';
export const RESET_VIEW_EDIT_PARTICIPANT = 'reset_view_edit_participant';
export const DUPLICATE_MULTI_RATER = 'duplicate_multi_rater';
export const DUPLICATE_SETTING = 'duplicate_setting';
export const DUPLICATE_SECTION_CONTENT = 'duplicate_section_content';
export const DUPLICATE_SCALE_CHOICE = 'duplicate_scale_choice';
export const DUPLICATE_SCORE = 'duplicate_score';
export const DUPLICATE_PARTICIPANT = 'duplicate_participant';
export const SET_CONTENT_BULK = 'set_content_bulk';
/* PREVIEW */
export const SET_DATA_PREVIEW_MR = 'set_data_preview_mr';
export const CLEAR_DATA_PREVIEW_MR = 'clear_data_preview_mr';
/* VALIDATION */
export const SET_VALID_SETTING = 'set_valid_setting';
export const SET_VALID_CONTENT = 'set_valid_content';
export const SET_VALID_PARTICIPANT = 'set_valid_participant';
export const RESET_VALID_INPUT = 'reset_valid_input';

export const SET_VIEW_EDIT_PARTICIPANT_KEY_EMAIL = 'set_view_edit_participant_key_email';
export const RESET_VIEW_EDIT_PARTICIPANT_KEY_EMAIL = 'reset_view_edit_participant_key_email';
