import { useEffect, useState } from "react";
import { useDeps } from "../../../../shared/context/DependencyContext";
import { useSelector } from "react-redux";
import { CONSTANTS } from "../../../../common/constants";

function useTabSettings() {
    const { generalService, adminService, picService } = useDeps();
    const [buData, setBuData] = useState([{ business_unit_name: "SELECT ALL", business_unit_code: "11111111" }]);
    const [goalData, setGoalData] = useState(null);
    const [departmentData, setDepartmentData] = useState([{ department_name: "SELECT ALL", department_code: "11111111" }]);
    const user = useSelector((state) => state.user);

    useEffect(() => {
        onGetBUData();
    }, [])

    useEffect(() => {
        onGetGoal();
    }, [])

    useEffect(() => {
        onGetDepartmentData();
    }, [])

    /* Goal Data */
    const onGetGoal = async () => {
        try {
            const response =
                user.role == CONSTANTS.ROLE.ADMIN ? await adminService.configDataService.getAllGoals() :
                    (user.role == CONSTANTS.ROLE.PIC ? await picService.configDataService.getAllGoalsPICUrl() : null);

            if (response && response.status == 'SUCCESS') {
                if (response.data != 'NO DATA') {
                    setGoalData(response.data);
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    /* BU Data */
    const onGetBUData = async () => {
        try {
            const response = await generalService.generalDataService.getBusinessUnitData();

            if (response.status == 'SUCCESS') {
                setBuData([...buData, ...response.data]);
            } else {
                setBuData([]);
            }
        } catch (error) {
            console.log(error)
        }
    }

    /* Dept Data */
    const onGetDepartmentData = async () => {
        try {
            const response = await generalService.generalDataService.getDepartmentData();

            if (response.status == 'SUCCESS') {
                setDepartmentData([...departmentData, ...response.data]);
            }
        } catch (error) {
            console.log(error)
        }
    }

    return {
        onGetGoal,
        onGetBUData,
        onGetDepartmentData,
        goalData,
        buData,
        departmentData,
    }
}

export default useTabSettings;