export const SET_SETTING_SINGLE = 'set_setting_single';
export const SET_SINGLE_RATER = 'set_single_rater';
export const SET_VIEW_SINGLE = 'set_view_single';
export const SET_SECTION_SINGLE = 'set_section_single';
export const EDIT_SECTION_SINGLE = 'edit_section_single';
export const DELETE_SECTION_SINGLE = 'delete_section_single';
export const SET_CONTENT_SINGLE = 'set_content_single';
export const EDIT_CONTENT_SINGLE = 'edit_content_single';
export const DELETE_CONTENT_SINGLE = 'delete_content_single';
export const SET_CONTENT_CHOICE_SINGLE = 'set_content_choice_single';
export const SET_CONTENT_CHOICE_SCALE_SINGLE = 'set_content_choice_scale_single';
export const EDIT_CONTENT_CHOICE_SINGLE = 'edit_content_choice_single';
export const DELETE_CONTENT_CHOICE_SINGLE = 'delete_content_choice_single';
export const SET_VIEW_CONTENT_SINGLE = 'set_view_content_single';
export const CLEAR_VIEW_CONTENT_SINGLE = 'clear_view_content_single';
export const SET_SCALE_CHOICE_SINGLE = 'set_scale_choice_single';
export const ADD_SCALE_CHOICE_SINGLE = 'add_scale_choice_single';
export const EDIT_SCALE_CHOICE_SINGLE = 'edit_scale_choice_single';
export const DELETE_SCALE_CHOICE_SINGLE = 'delete_scale_choice_single';
export const CLEAR_SCALE_CHOICE_SINGLE = 'clear_scale_choice_single';
export const SET_SCORE_SINGLE = 'set_score_single';
export const SET_PARTICIPANT_SINGLE = 'set_participant_single';
export const DELETE_PARTICIPANT_SINGLE = 'delete_participant_single';
export const EDIT_PARTICIPANT_SINGLE = 'edit_participant_single';
export const SET_VIEW_EDIT_PARTICIPANT_SINGLE = 'set_view_edit_participant_single';
export const RESET_VIEW_TAB_SINGLE = 'reset_view_tab_single';
export const RESET_SINGLE_RATER = 'reset_single_rater';
export const RESET_SETTING_SINGLE = 'reset_setting_single';
export const RESET_SECTION_SINGLE = 'reset_section_single';
export const RESET_CONTENT_VIEW_SINGLE = 'reset_content_view_single';
export const RESET_SCALE_CHOICE_SINGLE = 'reset_scale_choice_single';
export const RESET_SCORE_SINGLE = 'reset_score_single';
export const RESET_PARTICIPANT_SINGLE = 'reset_participant_single';
export const RESET_VIEW_EDIT_PARTICIPANT_SINGLE = 'reset_view_edit_participant_single';
export const SET_CONTENT_BULK_SR = 'set_content_bulk_sr';

/* DUPLICATE */
export const DUPLICATE_SINGLE_RATER = 'duplicate_single_rater';
export const DUPLICATE_SETTING_SR = 'duplicate_setting_sr';
export const DUPLICATE_SECTION_CONTENT_SR = 'duplicate_section_content_sr';
export const DUPLICATE_SCALE_CHOICE_SR = 'duplicate_scale_choice_sr';
export const DUPLICATE_SCORE_SR = 'duplicate_score_sr';
export const DUPLICATE_PARTICIPANT_SR = 'duplicate_participant_sr';

/* PREVIEW */
export const SET_DATA_PREVIEW_SR = 'set_data_preview_sr';
export const CLEAR_DATA_PREVIEW_SR = 'clear_data_preview_sr';

/* VALIDATION */
export const SET_VALID_SETTING = 'set_valid_setting_sr';
export const SET_VALID_CONTENT = 'set_valid_content_sr';
export const SET_VALID_PARTICIPANT = 'set_valid_participant_sr';
export const RESET_VALID_INPUT = 'reset_valid_input_sr';

export const SET_VIEW_EDIT_PARTICIPANT_SR_KEY_EMAIL = 'set_view_edit_participant_sr_key_email';
export const RESET_VIEW_EDIT_PARTICIPANT_SR_KEY_EMAIL = 'reset_view_edit_participant_sr_key_email';